var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-categoria-patrocinadores-sidebar","visible":_vm.isEditSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Editar patrocinador")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('p',[_vm._v("Categoria: "+_vm._s(_vm.category))]),_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Titulo","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Link","label-for":"link"}},[_c('b-form-input',{attrs:{"id":"link","state":errors.length > 0 ? false : null},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"logo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Logo","label-for":"logo"}},[_c('b-form-file',{attrs:{"id":"logo","drop-placeholder":"Arraste seu arquivo para aqui...","state":errors.length > 0 ? false : null},on:{"change":_vm.previewImage},model:{value:(_vm.form.logo),callback:function ($$v) {_vm.$set(_vm.form, "logo", $$v)},expression:"form.logo"}}),(_vm.previewUrl)?_c('img',{staticStyle:{"max-width":"200px","margin-top":"20px"},attrs:{"src":_vm.previewUrl,"alt":"Prévia da imagem"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Atualizar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancelar ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }